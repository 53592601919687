<template>
  <div class="Evaluate_I">
    <div class="headNavs">
      <div class="headTilte">用户评价</div>
      <div class="headDetails">说明：包含商品评价、状态、详情等</div>
    </div>

    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnSearch()"><i class="el-icon-refresh"></i>刷新</div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        header-cell-class-name="table-header"
        @selection-change="handleSelect"> 
        <el-table-column prop="id" label="ID" width="80" align="center"></el-table-column>
        <el-table-column align="center" prop="images" label="评价图片">
              <template #default="scope" >
               <img class="Toper" :src="scope.row.full_avatar" alt="">
             </template>
        </el-table-column>
        <el-table-column align="center" prop="goods.goods_short_name" label="评价商品"></el-table-column>
        <el-table-column align="center" prop="user.nickname" label="服务商" ></el-table-column>
        <el-table-column align="center" prop="info" label="评价内容"></el-table-column>
        <el-table-column align="center" prop="createtime" label="评价时间"></el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template #default="scope">
            <div class="el_butto" >
              <el-button
                  type="text"
                  @click="HandleEdit(scope.row)"
              >查看
              </el-button>
            </div>
            <div class="el_butto rere" >
              <el-button
                  type="text"
                  class="red"
                  @click="HandleDel(scope.row)"
              >删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog title="评论详情" v-model="editVisible1" width="40%"  height="400px">
      <div class="EditNewForm" style="padding:0;">
        <div class="invoice">
          <div class="invoiceLi">
            <div>用户</div>
            <div>{{user.nickname}}</div>
          </div>
          <div class="invoiceLi">
            <div>评价商品</div>
            <div>{{goods.goods_name}}</div>
          </div>
          <div class="invoiceLi">
            <div>服务商</div>
            <div>{{business.username}}</div>
          </div>
          <div class="invoiceLi">
            <div>服务星级</div>
            <div>{{commentList.star}}</div>
          </div>
          <div class="invoiceLi" style="width: 100%;">
            <div class="LiAAAA">评价时间</div>
            <div>{{commentList.createtime}}</div>
          </div>
          <div class="invoiceLi" style="width: 100%;">
            <div class="LiAAAA">评价标签</div>
            <div v-for="item in tagList" :key="item">{{item}}&nbsp;&nbsp;&nbsp;</div>
          </div>
          <div class="invoiceLi" style="width: 100%;">
            <div class="LiAAAA">评价内容</div>
            <div>{{commentList.info}}</div>
          </div>
        </div>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "index",
  data() {
    return {
      tableData:[],//总列表
      // 分页
      page: {
        currentPage: 1,
        pageSize: 10,
      },
      total: 0,
      // 列表数据
      Row: {
        name: "",
        status: '',
        weigh: "",
      },
      //搜索参数
      search: {
        keyword: "",
      },
      editVisible1:false,
      tagList:[],
      commentList:[],
      user:{},
      goods:{},
      business:{}
      
    }
  },
  mounted() {
    var that = this
    that.loadTable();
  },
  methods: {  
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/order/comment/index',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    },
    //搜索
    BtnSearch() {
      var that = this
      that.page.currentPage = 1;
      that.loadTable();
    },
    //获取编辑内容
    HandleEdit(data) {
      var that = this
      that.editVisible1 = true;
      axios.get('/admin/order/comment/read', {   // 详情
        params: {
          id: data.id
        }
      }).then(function (res) {
        console.log(res.data.data);
        that.tagList = res.data.data.tagList
        that.user = res.data.data.row.user ? res.data.data.row.user : ''
        that.goods = res.data.data.row.goods ? res.data.data.row.goods :''
        that.business = res.data.data.row.business ? res.data.data.row.business : ''
        that.commentList = res.data.data.row
       
      })
    },
    // 取消
    Close() {
      var that = this
      that.editVisible1 = false;
    },
    //删除
    HandleDel(e, data) {
      var that = this
      var arr = []
      arr.push(data.id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
          method: 'DELETE',
          url: '/admin/goods/label/del',
          data: {
            ids: arr
          }
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.loadTable();
          }
        })
      })
    },
    //分页
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    },
  }
}
</script>

<style scoped>

</style>
